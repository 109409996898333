import { AntarConstants } from '../common/antar.constants';
import { DataPoints, TicCurveResponse } from '../common/models/antar-api-response.model';
import { DeviceData, TransformerDeviceData } from '../common/models/antart-api-request.model';

export class TicUtils {
	public static generateTicCurvePoints ( ticCurveParameters:TransformerDeviceData[] ):TicCurveResponse[] {
		const ticCurveResponses:TicCurveResponse[] = [];
		ticCurveParameters.forEach( ( ticCurveData: DeviceData ) => {
			ticCurveResponses.push( this.getTicCurveDataPoints( ticCurveData ) );
		} );
		return ticCurveResponses;
	}
	private static getTicCurveDataPoints ( ticCurveData:TransformerDeviceData ):TicCurveResponse
	{
		const paramTrIn = ticCurveData.deviceNetworkParameters.find( param => param.paramName == AntarConstants.TIC_PARAM['TrIn'] )?.paramValue;
		const paramNe = ticCurveData.deviceNetworkParameters.find( param => param.paramName == AntarConstants.TIC_PARAM['Ne'] )?.paramValue;
		const paramTe = ticCurveData.deviceNetworkParameters.find( param => param.paramName == AntarConstants.TIC_PARAM['Te'] )?.paramValue;
		const ticDataPoints: DataPoints = {
			curveName: AntarConstants.CEL_CURVE_NAME['Min'],
			points: []
		} ;
		if ( paramTrIn != null && paramNe != null && paramTe != null ) {
			AntarConstants.TIC_CURVE_T_VALUES.forEach( tValue => {
				const mValue = parseFloat( paramTrIn ) * parseFloat( paramNe ) * ( Math.exp( -tValue * parseFloat( paramTe ) ) );
				ticDataPoints.points?.push( { x: mValue, y: tValue } );
			} );
		}
		return { ticId: ticCurveData.deviceId,
			deviceName: ticCurveData.deviceName,
			deviceType: ticCurveData.protectionDeviceType,
			power: ticCurveData.power,
			voltage: ticCurveData.voltage,
			dataPoints: [ ticDataPoints ] };

	}
}
