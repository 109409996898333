import { createAction, props } from '@ngrx/store';
import { User } from '../core/models/user.model';
import { RequestData } from '../common/models/antart-api-request.model';
import { Notifications, Response } from '../common/models/antar-api-response.model';

export const LoadUser = createAction(
	'[Antar] Load User Data',
	props<{user: User}>()
);
export const LoadInputDeviceData = createAction(
	'[Antar] Load Input Device Data',
	props<{inputDeviceData: RequestData}>()
);
export const LoadGraphData = createAction(
	'[Antar] Load Graph Data for Input Request'
);
export const LoadResponseDataSuccess = createAction(
	'[Antar] Load Backend Response Data Success',
	props<{responseData: any}>()
);
export const UpdateInputRequestData = createAction(
	'[Antar] Load Changed Settings to Input Request',
	props<{deviceId:string, paramName:string, paramValue:string, checked?: boolean}>()
);
export const ResetStates = createAction(
	'[Antar] Reset the State'
);
export const LoadSelectivityResultData = createAction(
	'[Antar] Load Selectivity Result Data'

);
export const LoadSelectivityResultDataSuccess = createAction(
	'[Antar] Load Selectivity Result Data Success',
	props<{selectivity:any}>()
);
export const UpdateUserSettingsData = createAction(
	'[Antar] Update the UserSettings',
	props<{deviceId:string, settingName:string, checked?:boolean, value: string}>()
);
export const UpdateLoader = createAction(
	'[Antar] Update Loader',
	props<{loadingtype:string, loader:boolean, message:string}>()
);
export const SetErrorMessage = createAction(
	'[Antar] Load Backend Error Message',
	props<{notification:Notifications[]}>()
);
export const UpdateUserSelection = createAction(
	'[Antar] Update User Selection',
	props<{chartType: string}>()
);
export const UpdateRcdDeviceRequest = createAction(
	'[Antar] Update Rcd Request',
	props<{deviceId:string, paramName:string, paramValue:string}>()
);
export const LoadSelectivityResultDataByTable = createAction(
	'[Antar] Load Selectivity Result Data By Table'
);
export const LoadSelectivityResultDataByTableSuccess = createAction(
	'[Antar] Load Selectivity Result Data By Table Success',
	props<{selectivity:Response}>()
);


export const SetTicCurveResponse = createAction(
	'[Antar] Load Tic Curve Response'
);
